.damage-block {
  position: absolute;
  z-index: 1;
  overflow: visible;
  width: 100%;
}

.fadeout-enter.label, .fadeout-appear.label {
  opacity: 1;
  transform: translateY(0);
}
.fadeout-enter-active.label, .fadeout-appear-active.label {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 2000ms ease-out, transform 2000ms ease-out;
}
.fadeout-enter-done, .fadeout-appear-done {
  display: none;
}
.fadeout-exit {
  opacity: 1;
}
.fadeout-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}


.fadein-enter {
  opacity: 0;
}
.fadein-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fadein-exit {
  opacity: 1;
}
.fadein-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
