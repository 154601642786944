.sp-slider-nav {
  /* position: absolute;
  height: 20px;
  width: 100%;
  top: 50px;
  z-index: 10; */
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.sp-slider-nav .dot {
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
  background-color: var(--ion-color-light);
  margin-right: 5px;
  display: flex;
  align-content: center;
}

.sp-slider-nav .dot:last-child {
  margin-right: 0;
}

.sp-slider-nav .dot.active {
  background-color: var(--ion-color-primary);
}