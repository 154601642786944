.system {
  position: absolute;
  font-size: 12px;
  background-color: transparent;
  color: white;
  width: 80px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.system:focus {
  outline:0;
}

.system:hover {
  background-color: black;
  border-color: white !important;
}

.tooltip {
  position: absolute;
  left: 35px;
  top: 35px;
  width: 200px;
  background-color: rgba(0, 0, 0, .9);
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  z-index: 3000;
}
