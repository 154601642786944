* {
  font-smooth: never;
  -webkit-font-smoothing : none;
}

.pixelated {
  image-rendering: pixelated;
}

.semi-transparent {
  --background: rgba(0, 0, 0, 0.5);
}

.transparent {
  --background: rgba(0, 0, 0, 0);
}

.progressbar {
  --buffer-background: rgba(0, 0, 0, 1);
}

.bg-grey {
  --background: rgba(255, 255, 255, 0.1);
}

.code {
  font-family: monospace;
}

.fixed {
  position: fixed;
}

.bottom {
  bottom: 0px;
}
