.segmented-progressbar {
  display: flex;
  width: 100%;
}

.segmented-progressbar .item {
  width: 100%;
  background-color: var(--ion-color-medium);
  margin-right: 4px;
  height: 16px;
}

.segmented-progressbar .item.filled {
  background-color: var(--ion-color-primary);
}

.segmented-progressbar .item.selected {
  background-color: var(--ion-color-success);
}
