.space {
  --background-color: black;
  min-height: 200px;
}

.map {
  width: 1050px;
  height: 790px;
  position: relative;
  margin: 70px;
}

.available-distance {
  position: absolute;
  border-radius: 100%;
  border: 1px solid green;
}

.actions {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
