.action-toast {
  font-size: 1rem;
  text-align: center;
}

.action-toast .toast-header {
  font-size: 1.5rem !important;
}

.action-toast .toast-message {
  font-size: 1.5rem !important;
  margin-top: 1rem !important;
}